/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRef } from "react";

import type { StrapiCustomerLogoReel } from "@/types/strapi";

import { Spacing } from "@/tokens/spacing";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Picture } from "@/ui/atoms/picture";
import { Text } from "@/ui/atoms/text";

import { useHorizontalLoop } from "@/util/animation_hooks/horizontal_loop_animation";
import { useArrayRef } from "@/util/hooks/ref_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { getSectionGap } from "@/util/tokens/layout_util";
import { convertToRem } from "@/util/ui_util";

interface CustomerLogoReelProps extends StrapiCustomerLogoReel {}

export function CustomerLogoReel(props: CustomerLogoReelProps) {
    /**
     * Refs
     */
    const containerRef = useRef(null);
    const [listItemsRef, setListItemsRef] = useArrayRef<HTMLLIElement>();

    /**
     * Animations
     */
    useHorizontalLoop(listItemsRef, {
        repeat: -1,
        speed: 0.5,
    });

    /**
     * Styles
     */
    const containerStyles = css({
        marginTop: Spacing["spacing-6"],
        overflow: "hidden",
    });

    const logoListStyles = css(
        {
            display: "flex",
            maskImage:
                "radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.33) 50%, rgba(0, 0, 0, 0) 90%)",
        },
        buildStylesByBreakpoint("columnGap", {
            extraSmall: Spacing["spacing-10"],
            medium: Spacing["spacing-18"],
        }),
    );

    const logoStyles = css(
        {
            display: "block",
            width: "auto",
        },
        buildStylesByBreakpoint("height", {
            extraSmall: convertToRem(20),
            medium: convertToRem(24),
        }),
    );

    return (
        <GridContainer
            legacyGrid={false}
            marginBottom={getSectionGap(props.Section_Gap)}
        >
            <GridColumn>
                <Text
                    shouldBalanceWrap
                    fontSize="EyeBrow"
                    textAlign="center"
                    themeKey="textSecondary"
                >
                    {props.Title}
                </Text>

                <GridColumn className={containerStyles} ref={containerRef}>
                    <ul css={logoListStyles}>
                        {props.Logos.map((logo, i) => (
                            <li key={i} ref={setListItemsRef}>
                                <Picture
                                    {...logo}
                                    borderRadius="borderNone"
                                    className={logoStyles}
                                />
                            </li>
                        ))}

                        {/* Add a dummy item to provide space between the last and first items when looping */}
                        <li ref={setListItemsRef} />
                    </ul>
                </GridColumn>
            </GridColumn>
        </GridContainer>
    );
}
