import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

import type { TypedArrayRefObject } from "@/types/interactivity";

type Config = {
    paddingRight?: `${number}px`;
    repeat?: number;
    reversed?: boolean;
    speed?: number;
};

export function useHorizontalLoop(
    itemsRef: TypedArrayRefObject,
    config: Config,
) {
    useGSAP(() => {
        if (itemsRef.current) {
            horizontalLoop(
                itemsRef.current.filter((el) => !!el) as HTMLElement[],
                config,
            );
        }
    }, []);
}

// https://gsap.com/docs/v3/HelperFunctions/helpers/seamlessLoop/#usage
function horizontalLoop<T extends HTMLElement>(
    items: T[],
    config: Config = {},
): gsap.core.Timeline {
    const tl = gsap.timeline({
        defaults: { ease: "none" },
        onReverseComplete() {
            tl.totalTime(tl.rawTime() + tl.duration() * 100);
        },
        repeat: config.repeat,
    });

    const defaultSpeed = 1;

    const length = items.length;
    const pixelsPerSecond = (config.speed || defaultSpeed) * 100;
    const snap = gsap.utils.snap(1);
    const startX = items[0].offsetLeft;
    const times: number[] = [];
    const widths: number[] = [];
    const xPercents: number[] = [];

    gsap.set(items, {
        /*
         * convert "x" to "xPercent" to make things responsive,
         * and populate the widths/xPercents Arrays to make lookups faster.
         */
        xPercent(i, el) {
            const w = parseFloat(gsap.getProperty(el, "width", "px") as string);
            const x = parseFloat(gsap.getProperty(el, "x", "px") as string);

            const xPercent = snap(
                (x / w) * 100 + (gsap.getProperty(el, "xPercent") as number),
            );

            widths[i] = w;
            xPercents[i] = xPercent;

            return xPercent;
        },
    });

    gsap.set(items, { x: 0 });

    const lastItem = items[length - 1];

    const totalWidth =
        lastItem.offsetLeft +
        (xPercents[length - 1] / 100) * widths[length - 1] -
        startX +
        lastItem.offsetWidth *
            (gsap.getProperty(lastItem, "scaleX") as number) +
        (config.paddingRight ? parseFloat(config.paddingRight) : 0);

    for (let i = 0; i < length; i++) {
        const item = items[i];
        const x = (xPercents[i] / 100) * widths[i];
        const scaleX = gsap.getProperty(item, "scaleX") as number;

        const distanceToStart = item.offsetLeft + x - startX;
        const distanceToLoop = distanceToStart + widths[i] * scaleX;

        tl.to(
            item,
            {
                duration: distanceToLoop / pixelsPerSecond,
                xPercent: snap(((x - distanceToLoop) / widths[i]) * 100),
            },
            0,
        )
            .fromTo(
                item,
                {
                    xPercent: snap(
                        ((x - distanceToLoop + totalWidth) / widths[i]) * 100,
                    ),
                },
                {
                    duration:
                        (x - distanceToLoop + totalWidth - x) / pixelsPerSecond,
                    immediateRender: false,
                    xPercent: xPercents[i],
                },
                distanceToLoop / pixelsPerSecond,
            )
            .add("label" + i, distanceToStart / pixelsPerSecond);

        times[i] = distanceToStart / pixelsPerSecond;
    }

    let currentIndex = 0;

    function toIndex(
        index: number,
        vars: gsap.TweenVars = {},
    ): gsap.core.Tween {
        Math.abs(index - currentIndex) > length / 2 &&
            (index += index > currentIndex ? -length : length); // always go in the shortest direction
        const newIndex = gsap.utils.wrap(0, length, index);
        let time = times[newIndex];
        if (time > tl.time() !== index > currentIndex) {
            // if we're wrapping the timeline's playhead, make the proper adjustments
            vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
            time += tl.duration() * (index > currentIndex ? 1 : -1);
        }
        currentIndex = newIndex;
        vars.overwrite = true;
        return tl.tweenTo(time, vars);
    }

    tl.next = (vars?: gsap.TweenVars) => toIndex(currentIndex + 1, vars);
    tl.previous = (vars?: gsap.TweenVars) => toIndex(currentIndex - 1, vars);
    tl.current = () => currentIndex;
    tl.toIndex = (index: number, vars?: gsap.TweenVars) => toIndex(index, vars);
    tl.times = times;
    tl.progress(1, true).progress(0, true); // pre-render for performance
    if (config.reversed) {
        tl.vars.onReverseComplete?.();
        tl.reverse();
    }
    return tl;
}
